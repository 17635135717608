import { GlobalStyles, styled } from "@infinitaslearning/pixel-design-system";
import type { FC } from "react";

type GlobalStyleProps = {
  scrollbarColor: string;
};

const GlobalStyle: FC<GlobalStyleProps> = ({ scrollbarColor }: GlobalStyleProps) => {
  return (
    <GlobalStyles
      styles={{
        "::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "#__next": {
          height: "100%",
          width: "100%",
        },
        "::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 1px ${scrollbarColor}`,
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          background: scrollbarColor,
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: scrollbarColor,
        },

        height: "100%",
        boxSizing: "border-box",
      }}
    />
  );
};

export default GlobalStyle;

export const PupilPlatform = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Main = styled("main")`
  height: 100%;
  z-index: 0;
  overflow-y: auto;
`;
