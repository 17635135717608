import React from "react";

const CLARITY_MASK = "True";

type DataClarityWrapperProps = {
  children: React.ReactNode;
};

export const DataClarityWrapperSpan = ({ children }: DataClarityWrapperProps) => (
  <span data-clarity-mask={CLARITY_MASK}>{children}</span>
);
