import { getTallyToken } from "@lib/tally/get-token";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { CsatShownInfo } from "@lib/tally/useTallyForm";

export const TALLY_SHOWN_INFO_KEY_CSAT = "tally-shown-info-csat";

export const useCsat = ({
  enabled,
  formId,
  userId,
  organizationId,
  language,
  setCsatShownInfo,
}: {
  enabled: boolean;
  formId?: string;
  userId?: string;
  organizationId: string;
  language: string;
  setCsatShownInfo: (update: (csatShownInfo: CsatShownInfo | undefined) => CsatShownInfo) => void;
}) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const { data: tallyToken } = useQuery({
    queryKey: ["tallyToken-csat"],
    queryFn: getTallyToken,
    enabled,
  });

  useEffect(() => {
    if (!tallyToken || !formId || !userId) return;

    if (enabled) {
      window.Tally?.openPopup(formId, {
        hiddenFields: {
          organizationId,
          language,
          token: tallyToken,
          surveyId: `pep-csat-${formId}`,
        },
        emoji: {
          text: "👋",
          animation: "wave",
        },
        onOpen: () => {},
        onClose: () => {
          if (hasBeenSubmitted) return;

          setCsatShownInfo((oldState) => ({
            ...(oldState || {}),
            [userId]: {
              shownOn: new Date().toString(),
              formId,
            },
          }));
        },
        onSubmit: () => {
          setHasBeenSubmitted(true);
          setCsatShownInfo((oldState) => ({
            ...(oldState || {}),
            [userId]: {
              shownOn: new Date().toString(),
              formId,
            },
          }));
        },
      });
    } else {
      window.Tally?.closePopup(formId);
    }
  }, [
    tallyToken,
    formId,
    language,
    userId,
    hasBeenSubmitted,
    organizationId,
    setCsatShownInfo,
    enabled,
  ]);
};
