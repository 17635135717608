import { UserContext } from "@components/user-context/user-context";
import { useGetPreferencesJsonQuery, useSaveUserPreferencesMutation } from "@generated/graphql";
import { isFormEnabled, parseTallyConfig } from "@lib/tally/hooks-utils";
import { useCsat } from "@lib/tally/useCsat";
import { useBrand } from "@utils/use-brand";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useCallback, useContext, useMemo } from "react";
import * as z from "zod";

type TallyForms = "CSAT";
type TallyFormsPriority = { key: TallyForms; enabled: boolean }[];

const useUserPreferences = () => {
  const { data, isSuccess } = useGetPreferencesJsonQuery();
  const { mutateAsync } = useSaveUserPreferencesMutation();

  const preferences: Record<string, unknown> = data?.me.preferencesJson;

  const setPreferences = useCallback(
    (settings: unknown) => {
      if (isSuccess) {
        mutateAsync({
          preferencesJson: JSON.stringify({
            ...(preferences?.data ?? {}),
            ...(settings ?? {}),
          }),
        });
      }
    },
    [isSuccess, preferences, mutateAsync],
  );

  return {
    preferences,
    setPreferences,
  };
};

const zodTallyShowAfterDate = z.record(z.string(), z.string());
export type TallyShowAfterDate = z.infer<typeof zodTallyShowAfterDate>;

const zodTallyShownInfo = z.record(
  z.string(),
  z.object({
    shownOn: z.string(),
    formId: z.string(),
  }),
);
export type CsatShownInfo = z.infer<typeof zodTallyShownInfo>;

const showTallyByPriority = (priority: TallyFormsPriority) => (key: TallyForms) => {
  for (const item of priority) {
    // If we reached our form, check if it is enabled
    if (item.key === key) return item.enabled;
    // If we didn't reach the form yet but a higher priority form is enabled we don't want to enable our (lower priority) form
    if (item.enabled) return false;
  }
  return false;
};

// Displays a tally form
// If multiple tally forms are configured to be shown, it will show the one with the highest priority only
export const useTallyForm = () => {
  // const router = useRouter();

  const userContext = useContext(UserContext);
  const userId = userContext?.me.data?.id;

  const tallyFormConfigString = useFeatureFlag<string>("tallyConfigCsat", "null");
  const tallyFormConfig = useMemo(
    () => parseTallyConfig(tallyFormConfigString),
    [tallyFormConfigString],
  );

  const { locale } = useBrand();
  const [language] = locale.split("-");

  const { preferences, setPreferences } = useUserPreferences();

  const csatShownInfo: CsatShownInfo | undefined = useMemo(
    () => zodTallyShownInfo.safeParse(preferences?.csatShownInfo).data,
    [preferences],
  );
  const setCsatShownInfo = useCallback(
    (update: (tallyShownInfo: CsatShownInfo | undefined) => CsatShownInfo) =>
      setPreferences({ csatShownInfo: update(csatShownInfo) }),
    [csatShownInfo, setPreferences],
  );

  const showCsat = useMemo(() => {
    const correctContext = !!tallyFormConfig && !!userId;
    const notShownYet = !!userId && !csatShownInfo?.[userId]?.shownOn;
    const enabledFromConfig = isFormEnabled(tallyFormConfig);

    return correctContext && notShownYet && enabledFromConfig;
  }, [tallyFormConfig, userId, csatShownInfo]);

  const show: Record<TallyForms, boolean> = useMemo(() => {
    const checkIfEnabled = showTallyByPriority([{ key: "CSAT", enabled: showCsat }]);

    return {
      CSAT: checkIfEnabled("CSAT"),
    };
  }, [showCsat]);

  useCsat({
    enabled: show.CSAT,
    formId: tallyFormConfig?.formId,
    userId,
    organizationId: userContext?.me.data?.organizationId || "",
    language,
    setCsatShownInfo,
  });
};
