import { Loading } from "@components/loading";
import { useUmcConfigsQuery } from "@generated/graphql";
import type { PropsWithChildren } from "react";
import { useFeatureFlag } from "@utils/use-feature-flag";

export type UmcConfigsMap = Record<string, { umcConfigId: string; umcConfigUpdatedAt: number }>;

export const UmcWrapper = ({ children }: PropsWithChildren) => {
  const useUmcAnnualPreparation = useFeatureFlag("umcAnnualPreparation", false);

  if (!useUmcAnnualPreparation) sessionStorage.removeItem("umcConfigsMap");

  const { data, isLoading } = useUmcConfigsQuery();

  if (data) {
    const umcConfigsMap = data.umcConfigs.reduce<UmcConfigsMap>((acc, umcConfig) => {
      acc[umcConfig.packageId] = {
        umcConfigId: umcConfig.id,
        umcConfigUpdatedAt: umcConfig.updatedAt,
      };
      return acc;
    }, {});

    if (useUmcAnnualPreparation) {
      sessionStorage.setItem("umcConfigsMap", JSON.stringify(umcConfigsMap));
    }
  }

  return isLoading ? <Loading /> : <>{children}</>;
};
