import { getApmWeb } from "@lib/observability";

export const getTallyToken = async () => {
  const res = await fetch("/api/tally-token");
  if (!res.ok) {
    getApmWeb().captureError(new Error("Unable to get the tally CSRF token"), "tally-get-token");
    return null;
  }

  return res.json();
};
