export const ellipsis = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const multiLineEllipsis = (lines: number) => ({
  display: "-webkit-box",
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": `${lines}`,
  maxHeight: `${lines * 1.5}rem`, // fallback for other browsers
  textOverflow: "ellipsis",
  overflow: "hidden",
});
