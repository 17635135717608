import { type MeQuery, type Student, useGetClassroomsQuery, useMeQuery } from "@generated/graphql";
import { setAnalyticsTrackerUser } from "@lib/analytics-tracker";
import { getAllValues } from "@lib/configcat";
import { isUserInRole } from "@utils/role-helper";
import { useBrand } from "@utils/use-brand";
import { createContext, type ReactNode, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export const FEATURE_TOGGLES_COOKIE = "feature-toggles-pupil";
export const FEATURE_TOGGLES_COOKIE_MAX_AGE = 31556952; // one year

export type FeatureFlag = {
  key: string;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  value: any;
  isToggleable: boolean;
};

export type UserContextType = {
  me: {
    isLoading: boolean;
    data: MeQuery["me"] | undefined;
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    error: any;
  };
  features: FeatureFlag[] | undefined;
  setFeatures: (features: FeatureFlag[] | undefined) => void;
  roles: {
    isPublisher: boolean;
    isAdmin: boolean;
    isTeacher: boolean;
  };
};

export type StudentContextType = {
  me: {
    isLoading: boolean;
    data: Student | undefined;
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    error: any;
  };
  features: FeatureFlag[] | undefined;
  roles: {
    isPublisher: boolean;
    isAdmin: boolean;
    isTeacher: boolean;
  };
};

const UserContext = createContext<UserContextType | undefined>(undefined);

const UserProvider = ({ children }: { children: ReactNode | ReactNode[] }): JSX.Element => {
  const { opCo, analyticsLocale } = useBrand();
  const [featuresData, setFeaturesData] = useState<FeatureFlag[] | undefined>(undefined);
  const { data, isInitialLoading: isLoadingMeData, error: errorMeData } = useMeQuery();
  const { t: configTranslation } = useTranslation("common", {
    keyPrefix: "config-toggles",
  });
  const [cookies] = useCookies([FEATURE_TOGGLES_COOKIE]);

  const meData = data?.me;

  useEffect(() => {
    async function fetchFeatures() {
      const features = await getAllValues(
        opCo,
        meData,
        {},
        configTranslation,
        cookies[FEATURE_TOGGLES_COOKIE],
      );
      setFeaturesData(features);
    }
    if (meData && !featuresData) fetchFeatures();
  }, [featuresData, meData, opCo, configTranslation, cookies]);

  const { data: classroomsData, isLoading: isLoadingClassrooms } = useGetClassroomsQuery(undefined);
  const classroomIds =
    classroomsData?.classroomsOfUser.map((data) => {
      return data.id;
    }) ?? [];

  // biome-ignore lint/correctness/useExhaustiveDependencies: It's missing here for a reason
  useEffect(() => {
    if (meData && !isLoadingClassrooms) {
      setAnalyticsTrackerUser(
        meData.id,
        meData.organizationId || "",
        meData.roles,
        analyticsLocale,
        classroomIds,
      );
    }
  }, [meData?.id]);

  const value = useMemo(
    () => ({
      me: {
        isLoading: isLoadingMeData,
        data: meData,
        error: errorMeData,
      },
      features: featuresData,
      setFeatures: setFeaturesData,
      roles: {
        isPublisher: isUserInRole("publisher", meData?.roles),
        isAdmin: isUserInRole("ict-coordinator", meData?.roles),
        isTeacher: isUserInRole("teacher", meData?.roles),
      },
    }),
    [isLoadingMeData, meData, errorMeData, featuresData],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
