import { UserContext } from "@components/user-context/user-context";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
import XmarkIcon from "@infinitaslearning/pixel-icons/Xmark";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { usePublicationEnv } from "@utils/persisted-values/use-publication-env";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataClarityWrapperSpan } from "@components/user-sidebar/components/data-clarity-span";
import { FeatureFlagSelector } from "@components/user-sidebar/components/feature-flag-selector";
import { getFullUserName } from "@utils/get-full-username";
import { getToggleGroupButtonSX } from "@utils/styling-helpers";

type UserSidebarProps = {
  closeSidebar: () => void;
  isOpen: boolean;
};

const trackElementClick = (elementId: string, value: string) => {
  getAnalyticsTracker()?.elementClicked({
    elementId,
    type: "button",
    module: "user-sidebar",
    itemId: elementId,
    value,
  });
};

const UserSidebar: React.FC<UserSidebarProps> = ({ isOpen, closeSidebar }) => {
  const userContext = useContext(UserContext);
  const [userEnv, setUserEnv] = usePublicationEnv();
  const isStaging = userEnv === "staging";
  const { t: tSidebar } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const { t: tCommon } = useTranslation("common");

  const theme = useTheme();
  const handleClose = (ctx: "click-away" | "close-button") => {
    closeSidebar();
    trackElementClick("close-pupil-sidebar", ctx);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={() => handleClose("click-away")}>
      <IconButton
        aria-label="close"
        onClick={() => handleClose("close-button")}
        sx={{ alignSelf: "flex-end" }}
      >
        <XmarkIcon fontSize="xLarge" />
      </IconButton>

      <Box
        data-usetiful="sidepanel"
        role="presentation"
        sx={(theme) => ({
          boxSizing: "content-box",
          width: 300,
          padding: theme.spacing(0, 2),
          display: "flex",
          flex: 1,
          flexDirection: "column",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1Medium">
              <DataClarityWrapperSpan>
                {getFullUserName(userContext?.me.data)}
              </DataClarityWrapperSpan>
            </Typography>
            <Typography variant="body1Light" sx={{ color: "neutrals.50", fontSize: 12 }}>
              ({tCommon("id")}
              <DataClarityWrapperSpan>{userContext?.me.data?.id}</DataClarityWrapperSpan>)
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        {userContext?.roles.isPublisher && (
          <ToggleButtonGroup
            color="primary"
            value={isStaging ? "stag" : "prod"}
            exclusive
            onChange={(_, newValue: string) => {
              const trackValue = newValue ? newValue : userEnv ?? "production";
              trackElementClick("pupil-env-toggle", trackValue);
              setUserEnv(newValue === "stag" ? "staging" : "production");
            }}
            sx={{
              justifyContent: "center",
              gap: 0,
              // set border for children broken by pixel version 29.4.3
              button: {
                border: `1px solid ${theme.pixel.color.palette.neutrals[80]} !important`,
              },
            }}
          >
            <ToggleButton value="prod" sx={getToggleGroupButtonSX("left", true)}>
              {tSidebar("production_endpoint_label")}
            </ToggleButton>
            <ToggleButton value="stag" sx={getToggleGroupButtonSX("right", true)}>
              {tSidebar("staging_endpoint_label")}
            </ToggleButton>
          </ToggleButtonGroup>
        )}

        <FeatureFlagSelector />
      </Box>
    </Drawer>
  );
};

export default UserSidebar;
