type UserName = {
  firstName?: string;
  prefix?: string;
  lastName?: string;
};

export const getFullUserName = (user: UserName | undefined, abbreviateLastName?: boolean) => {
  if (!user) return "";
  const abbreviatedUserName: UserName = { ...user, lastName: `${user.lastName?.charAt(0)}.` };
  const userName = abbreviateLastName ? abbreviatedUserName : user;
  return `${getNameValue(userName.firstName)}${getNameValue(userName.prefix)}${getNameValue(
    userName.lastName,
  )}`;
};

const getNameValue = (value: string | undefined) => {
  if (value) return `${value} `;
  return "";
};
