import { IconButton } from "@infinitaslearning/pixel-design-system";
import GearIcon from "@infinitaslearning/pixel-icons/Gear";
import type { Dispatch, SetStateAction } from "react";

type UserSideBarIconButtonProps = {
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export const UserSideBarIconButton = ({ setIsSidebarOpen }: UserSideBarIconButtonProps) => {
  return (
    <IconButton
      sx={{
        position: "absolute",
        right: "86px", // move out of the way of pdf toolbar
        bottom: "75px",
        zIndex: 1000,
        isolation: "isolate",
      }}
      variant="contained"
      color="secondary"
      onClick={() => setIsSidebarOpen(true)}
      data-cy="options"
    >
      <GearIcon />
    </IconButton>
  );
};
