import { UserProvider } from "@components/user-context/user-context";
import UserSidebar from "@components/user-sidebar/user-sidebar";
import { WebSocketClientProvider } from "@components/web-socket-context/web-socket-client-provider";
import { useTallyForm } from "@lib/tally/useTallyForm";
import { UmcWrapper } from "@pages/components/umc-wrapper/umc-wrapper";
import type { NextPageWithLayout } from "@pages/types";
import { useState } from "react";
import { PupilPlatform } from "src/styles/global-styling/global.styles";
import { isMeDataAlphaOrBeta } from "@lib/configcat";
import { useMeQuery } from "@generated/graphql";
import { UserSideBarIconButton } from "@components/user-sidebar/components/user-sidebar-icon-button";
import { useTrackExperiments } from "@lib/analytics-tracker/use-experiments";

type LoggedInViewProps = {
  Component: NextPageWithLayout;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  pageProps: any;
};

const UserComponents = ({ Component, pageProps }: LoggedInViewProps) => {
  useTrackExperiments();

  useTallyForm();

  const getLayout = Component.getLayout ?? ((page) => page);

  return <PupilPlatform>{getLayout(<Component {...pageProps} />)}</PupilPlatform>;
};

export const LoggedInView = ({ Component, pageProps }: LoggedInViewProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data } = useMeQuery();

  return (
    <UserProvider>
      <WebSocketClientProvider>
        <UmcWrapper>
          <UserComponents Component={Component} pageProps={pageProps} />
          {isMeDataAlphaOrBeta(data?.me) && (
            <UserSideBarIconButton setIsSidebarOpen={setIsSidebarOpen} />
          )}
          <UserSidebar isOpen={isSidebarOpen} closeSidebar={() => setIsSidebarOpen(false)} />
        </UmcWrapper>
      </WebSocketClientProvider>
    </UserProvider>
  );
};
